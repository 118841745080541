<template>
  <mobile v-if="gl.isMobile" class="mt-4" />
  <desktop v-else />
</template>

<script>
import { useGl } from '@store/ts/gl';

// components
import Desktop from './desktop.vue';
import Mobile from './mobile.vue';

export default {
    name: 'stats-index',
    components: {
        Mobile,
        Desktop,
    },
    setup() {
        const gl = useGl();
    
        return {
            gl,
        };
    },
};
</script>