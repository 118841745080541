// vue
import { ref, computed, onMounted, reactive } from 'vue';

// services
import BotsService from '@services/bots';

// i18n
import { useI18n } from 'vue-i18n';

// store
import { useGl } from '@store/ts/gl';
import { useTools } from '@store/tools';

// naive-ui
import {
    useNotification } from 'naive-ui';

export default function (props, context) {
    // store
    const gl = useGl();
    const tools = useTools();
  
    // ui
    const notification = useNotification();
  
    // i18n
    const { t } = useI18n();

    // vars
    const loading = ref(false);

    const canDeleteSomeFilterPreset = computed(() => tools.filterPresets.some(({ checked }) => checked));

    const onDeleteConfigPreset = async (id, setLoading = false) => {
        const i = tools.filterPresets.findIndex(el => el.id === id);

        tools.filterPresets[i].loading = true;
        if (setLoading)
            loading.value = true;

        try {
            const prepare = await BotsService.deletePreset({ id: [id] });

            if (prepare.data?.status) {
                tools.filterPresets = tools.filterPresets.filter(el => el.id != id );
            }

            // show messages
            prepare.postMessages.forEach(el => {
                notification[el.success ? 'success' : 'error']({
                    content: el.msg,
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            });

        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };

        tools.filterPresets[i].loading = false;
        if (setLoading)
            loading.value = false;
    };

    const onDeleteSomeFilterPreset = async () => {
        const ids = tools.filterPresets.filter(({ checked }) => checked).map(({ id }) => id);

        ids.forEach(id => {
            onDeleteConfigPreset(id, true);
        });
    };

    return {
        gl,
        tools,
        loading,
        canDeleteSomeFilterPreset,
        onDeleteConfigPreset,
        onDeleteSomeFilterPreset,
    };
}