<template>
<n-tabs
    type="card"
    size="small"
    v-model:value="currentTab">
    <n-tab-pane
        v-for="(tab, i) in tabs"
        :key="i"
        :name="tab.name"
        :tab="tab.title">
        <component
            v-if="!loading"
            :is="tab.component"
            @chacngeTab="currentTab = $event"></component>
        <section v-else class="px-3 mt-3">
            <n-skeleton class="w-full rounded-md mb-2" :height="100" :sharp="false" />
            <n-skeleton class="w-full rounded-md" :height="100" :sharp="false" />
        </section>
    </n-tab-pane>
</n-tabs>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NTabs,
    NTabPane,
    NSkeleton} from 'naive-ui';

export default {
    name: 'tools-presets-mobile',
    components: {
        NTabs,
        NTabPane,
        NSkeleton,
    },
    setup(props, context) {
        return {
            ...general(arguments),
        };
    },
};
</script>