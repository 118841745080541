<template>
<n-tabs
    type="card"
    size="medium"
    v-model:value="currentTab">
    <n-tab-pane
        v-for="(tab, i) in tabs"
        :key="i"
        :name="tab.name"
        :tab="tab.title">
        <component
            v-if="!loading"
            :is="tab.component"
            @chacngeTab="currentTab = $event"></component>
        <section v-else class="flex">
            <n-skeleton class="inline-block 3/12 mr-2 rounded-md" :height="120" :sharp="false" />
            <n-skeleton class="inline-block 3/12 mx-2 rounded-md" :height="120" :sharp="false" />
            <n-skeleton class="inline-block 3/12 mx-2 rounded-md" :height="120" :sharp="false" />
            <n-skeleton class="inline-block 3/12 ml-2 rounded-md" :height="120" :sharp="false" />
        </section>
    </n-tab-pane>
</n-tabs>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NTabs,
    NTabPane,
    NSkeleton } from 'naive-ui';

export default {
    name: 'tools-presets',
    components: {
        NTabs,
        NTabPane,
        NSkeleton,
    },
    setup(props, context) {
        return {
            ...general(arguments),
        };
    },
};
</script>