// vue
import { ref, computed, onMounted, reactive } from 'vue';

// services
import BotsService from '@services/bots';

// i18n
import { useI18n } from 'vue-i18n';

// store
import { useGl } from '@store/ts/gl';
import { useTools } from '@store/tools';

// naive-ui
import {
    useNotification } from 'naive-ui';

export default function (props, context) {
    // store
    const gl = useGl();
    const tools = useTools();

    // ui
    const notification = useNotification();

    // i18n
    const { t } = useI18n();

    const canDeleteSomeConfigPreset = computed(() => tools.configPresets.some(({ checked }) => checked));

    const onDeleteConfigPreset = async id => {
        const i = tools.configPresets.findIndex(el => el.id === id);

        tools.configPresets[i].loading = true;

        try {
            const prepare = await BotsService.deletePreset({ id: [id] });

            if (prepare.data?.status) {
                tools.configPresets = tools.configPresets.filter(el => el.id != id );
            }

            // show messages
            prepare.postMessages.forEach(el => {
                notification[el.success ? 'success' : 'error']({
                    content: el.msg,
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            });

        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };

        tools.configPresets[i].loading = false;
    };

    const onDeleteSomeConfigPreset = async () => {
        const ids = tools.configPresets.filter(({ checked }) => checked).map(({ id }) => id);

        ids.forEach(id => {
            onDeleteConfigPreset(id);
        });
    };

    return {
        gl,
        tools,
        canDeleteSomeConfigPreset,
        onDeleteConfigPreset,
        onDeleteSomeConfigPreset,
    };
}