<template>
<section class="flex flex-wrap items-stretch">
    <div v-for="preset in tools.filterPresets" :key="preset.id" class="presets w-3/12 mb-3">
        <n-card
            size="small"
            :bordered="false"
            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
            :segmented="{
              content: true,
            }">
            <template #header>
                <div class="flex justify-between items-center">
                    <rb-checkbox
                        :nowrap="false"
                        :label="preset.name"
                        v-model:checked="preset.checked" />

                    <n-button
                        quaternary
                        class="px-2"
                        :loading="preset.loading"
                        :disabled="preset.loading"
                        @click="onDeleteConfigPreset(preset.id)">
                        <n-icon
                            v-if="!preset.loading"
                            class="text-red-500 text-2xl cursor-pointer">
                            <DeleteDismiss24Regular />
                        </n-icon>
                    </n-button>
                </div>
            </template>
            <template v-if="preset?.description" #default>
                <div class="text-gray-500 dark:text-white/40 ml-2 text-left">
                    {{ preset.description }}
                </div>
            </template>
        </n-card>
    </div>
</section>
<n-divider />
<section class="flex justify-end">
    <n-button
        strong
        class="text-white/90 rounded-md"
        :color="gl.mainColor"
        :loading="loading"
        :disabled="!canDeleteSomeFilterPreset || loading"
        @click="onDeleteSomeFilterPreset">
        {{ tools.localization?.tools_buttons_delete_selected || 'Удалить выбранное' }}
    </n-button>
</section>
</template>

<script>
// general
import general  from './general';

// icons
import {
    DeleteDismiss24Regular } from '@vicons/fluent';

// components
import RbCheckbox from '@components/rb-checkbox';

// naive-ui
import {
    NIcon,
    NCard,
    NButton,
    NDivider,
    useNotification } from 'naive-ui';

export default {
    name: 'order-matrix-presets-desktop',
    components: {
        NCard,
        NIcon,
        NButton,
        NDivider,
        RbCheckbox,
        DeleteDismiss24Regular,
    },
    setup(props, context) {
        return {
            ...general(arguments),
        };
    },
};
</script>

<style lang="scss" scoped>
.presets {
    &:nth-child(4n + 2) {
        padding: 0 12px;
    }

    &:nth-child(4n + 4) {
        padding-left: 12px;
    }
}
</style>