<template>
<section class="px-3">
    <div v-for="preset in tools.configPresets" :key="preset.id" class="mb-2">
        <n-card
            size="small"
            :bordered="false"
            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-md"
            :segmented="{
              content: true,
            }">
            <template #header>
                <div class="flex justify-between items-center">
                    <rb-checkbox
                        :label="preset.name"
                        v-model:checked="preset.checked" />

                    <n-button
                        quaternary
                        class="px-2"
                        :loading="preset.loading"
                        :disabled="preset.loading"
                        @click="onDeleteConfigPreset(preset.id)">
                        <n-icon
                            v-if="!preset.loading"
                            class="text-red-500 text-xl cursor-pointer">
                            <DeleteDismiss24Regular />
                        </n-icon>
                    </n-button>
                </div>
            </template>
            <template v-if="preset.description" #default>
                <div class="text-gray-500 dark:text-white/40 ml-2 text-left text-xs">
                    {{ preset.description }}
                </div>
            </template>
        </n-card>
    </div>
    <n-button
        strong
        class="text-white/90 rounded-md w-full mt-4"
        :color="gl.mainColor"
        :disabled="!canDeleteSomeConfigPreset"
        @click="onDeleteSomeConfigPreset">
        {{ tools.localization?.tools_buttons_delete_selected || 'Удалить выбранное' }}
    </n-button>
</section>
</template>

<script>
// general
import general  from './general';

// icons
import {
    DeleteDismiss24Regular } from '@vicons/fluent';

// components
import RbCheckbox from '@components/rb-checkbox/mobile.vue';

// naive-ui
import {
    NIcon,
    NCard,
    NButton,
    NDivider } from 'naive-ui';

export default {
    name: 'config-presets-mobile',
    components: {
        NCard,
        NIcon,
        NButton,
        NDivider,
        RbCheckbox,
        DeleteDismiss24Regular,
    },
    setup(props, context) {
        return {
            ...general(arguments),
        };
    },
};
</script>